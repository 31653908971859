import {
  ApiHelper,
  ApiHelperWithData,
  ApiResponseWithData,
  BaseRequest,
  RULE_TMPL_API_URL,
  splitParams,
} from '..'
import { RuleTmplList } from '..'

/**
 * 메타 관련 API
 */
export class RuleTmplApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 룰 템플릿 다건 조회
   */
  list = (
    params: {
      tmplKn: number
      tmplNm: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ ruleTmplList: RuleTmplList[] }>> => {
    const url = RULE_TMPL_API_URL.list
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 템플릿 단건 조회
   */
  info = (
    params: {
      tmplId: number
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ ruleTmpl: RuleTmplList }>> => {
    const url = RULE_TMPL_API_URL.info
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 룰 템플릿 신규 등록
   */
  create = (
    params: {
      tmplNm: string
      tmplExpl?: string
      tmplCn?: string
      tmplKn: number
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ instCnt: number }>> => {
    const url = RULE_TMPL_API_URL.create
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 템플릿 업데이트
   */
  update = (
    params: {
      tmplId: number
      tmplNm: string
      tmplExpl?: string
      tmplCn?: string
      tmplKn: number
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ uptCnt: number }>> => {
    const url = RULE_TMPL_API_URL.update
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 템플릿 다건삭제
   */
  delete = (
    params: {
      tmplId: number[]
    } & BaseRequest,
  ): Promise<ApiResponseWithData<void>> => {
    const url = RULE_TMPL_API_URL.delete
    return this.withData.post(url, ...splitParams(params))
  }
}
