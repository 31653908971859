import {
  ApiHelper,
  ApiHelperWithData,
  ApiResponseWithData,
  BaseRequest,
  RULE_API_URL,
  splitParams,
} from '..'
import { RuleTblInfo } from '../model'

/**
 * 룰 관련 API
 */
export class VirtualTransApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 테이블 정보 조회
   */
  insert = (
    params: {
      seaiid: string
      nstatus?: string
      seairecord?: string
      targetTb: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ insertCnt: number }>> => {
    const url = '/p/api/eai-recvs-end/insert'
    return this.withData.postJson(url, ...splitParams(params))
  }
}
