import {
  ApiHelper,
  ApiHelperWithData,
  ApiResponseWithData,
  BaseRequest,
  RULE_API_URL,
  splitParams,
} from '..'
import {
  RuleDetailInfo,
  RuleList,
  RuleActnList,
  RuleActnDtlList,
  BftfCond,
  RuleUpdate,
  RuleHst,
  RuleInsert,
  RuleApply,
  RuleTblInfo,
} from '../model'

/**
 * 룰 관련 API
 */
export class RuleApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }
  /**
   * RULE 신규 등록
   */
  ruleInsert = (
    params: RuleInsert & BaseRequest,
  ): Promise<ApiResponseWithData<{ insertCnt: number }>> => {
    const url = RULE_API_URL.ruleInsert
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * RULE 정보 업데이트
   */
  ruleUpdate = (
    params: RuleUpdate & BaseRequest,
  ): Promise<ApiResponseWithData<{ uptCnt: number }>> => {
    const url = RULE_API_URL.ruleUpdate
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 리스트 검색
   */
  ruleList = (
    params: {
      ruleInfo: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ ruleList: RuleList[] }>> => {
    const url = RULE_API_URL.ruleList
    return this.withData.postJson(url, ...splitParams(params))
  }
  /**
   * 룰 상세정보 조회
   */
  ruleDetailInfo = (
    params: {
      ruleId: number
    } & BaseRequest,
  ): Promise<
    ApiResponseWithData<{
      ruleDetailInfo: RuleDetailInfo
      stringRuleCn: string
      ruleBftfCond: BftfCond[]
      tlgmInfoVarl: { fildKorNm: string; ruleVarlNm: string }[]
      ruleActnDtl: RuleActnDtlList[]
      ruleHst: RuleHst[]
    }>
  > => {
    const url = RULE_API_URL.ruleDetailInfo
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 룰 변수목록 목록조회
   */
  varlList = (
    params: {
      trxId: string
    } & BaseRequest,
  ): Promise<
    ApiResponseWithData<{ tlgmInfoVarl: { fildKorNm: string; ruleVarlNm: string }[] }>
  > => {
    const url = RULE_API_URL.varList
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 룰 조치정보 목록조회
   */
  actnList = (
    params: {
      actnNm: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ ruleActnList: RuleActnList[] }>> => {
    const url = RULE_API_URL.actnList
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 조치정보 단건조회
   */
  actnInfo = (
    params: {
      actnId: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ actnInfo: RuleActnList }>> => {
    const url = RULE_API_URL.actnInfo
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 조치정보 신규등록
   */
  actnCreate = (
    params: {
      actnNm: string
      mnumExecYn: 'Y' | 'N'
      delYn: 'Y' | 'N'
      regrId: string
      chgrId: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ procCnt: number }>> => {
    const url = RULE_API_URL.actnCreate
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 조치정보 업데이트
   */
  actnUpdate = (
    params: {
      actnId: string
      actnNm: string
      mnumExecYn: 'Y' | 'N'
      delYn: 'Y' | 'N'
      chgrId: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ procCnt: number }>> => {
    const url = RULE_API_URL.actnUpdate
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 조치정보 삭제
   */
  actnDelete = (
    params: {
      actnId: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<void>> => {
    const url = RULE_API_URL.actnDelete
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 룰 조치 상세 조회
   */
  actnDtlList = (
    params: {
      actnId: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ ruleActnDtlList: RuleActnDtlList[] }>> => {
    const url = RULE_API_URL.actnDtlList
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 룰 조치 상세 단건조회
   */
  actnDtlInfo = (
    params: {
      actnId: string
      sqno: number
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ ruleActnDtl: RuleActnDtlList }>> => {
    const url = RULE_API_URL.actnDtlInfo
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 조치 상세 신규등록
   */
  actnDtlCreate = (
    params: {
      actnId: string
      itemNm: string
      dataType: string
      lth: string
      inptMethCd: string
      cdId: number
      regrId: string
      chgrId: string
      frntLabl?: string
      backLabl?: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ procCnt: number }>> => {
    const url = RULE_API_URL.actnDtlCreate
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 조치 상세 업데이트
   */
  actnDtlUpdate = (
    params: {
      actnId: string
      sqno: number
      itemNm: string
      dataType: string
      lth: string
      inptMethCd: string
      cdId: number
      chgrId: string
      frntLabl?: string
      backLabl?: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ procCnt: number }>> => {
    const url = RULE_API_URL.actnDtlUpdate
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 조치 상세 삭제
   */
  actnDtlDelete = (
    params: {
      actnId: string
      sqno: number
    } & BaseRequest,
  ): Promise<ApiResponseWithData<void>> => {
    const url = RULE_API_URL.actnDelete
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 적용 데이터
   */
  ruleApply = async (
    params: RuleApply & //
      BaseRequest,
  ): Promise<void> => {
    const url = RULE_API_URL.ruleApply
    await this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 삭제
   */
  ruleDelete = (
    params: {
      ruleId: number
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ procCnt: number }>> => {
    const url = RULE_API_URL.ruleDelete
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 테이블 정보 조회
   */
  ruleTblInfo = (
    params: {
      tableName: string
      columnName?: string
      columnExpl?: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ tblList: RuleTblInfo[] }>> => {
    const url = RULE_API_URL.ruleTblInfo
    return this.withData.postJson(url, ...splitParams(params))
  }
}
