import {
  ApiHelper,
  ApiHelperWithData,
  ApiResponseWithData,
  BaseRequest,
  TRX_INFO_API_URL,
  splitParams,
} from '..'
import { TrxInfo, PagerData } from '../model'

/**
 * 룰 인터페이스 API
 */
export class TrxInfoApi {
  private withData: ApiHelperWithData
  constructor(private helper: ApiHelper) {
    this.withData = new ApiHelperWithData(helper)
  }

  /**
   * 룰 인터페이스 목록 조회 - 페이징
   */
  trxLinfoList = (
    params: {
      trxId?: string
      trxNm?: string
      rowsPerPage: number
      pageNumber: number
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ pagerData: PagerData<TrxInfo> }>> => {
    const url = TRX_INFO_API_URL.trxLinfoList
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 인터페이스 단건 조회
   */
  info = (
    params: {
      trxId: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ trxInfo: TrxInfo }>> => {
    const url = TRX_INFO_API_URL.info
    return this.withData.post(url, ...splitParams(params))
  }

  /**
   * 룰 인터페이스 신규 등록
   */
  create = (
    params: {
      trxId: string
      trxNm: string
      trxExpl?: string
      ruleUseYn: 'Y' | 'N'
      mainTblNm?: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ instCnt: number }>> => {
    const url = TRX_INFO_API_URL.create
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 인터페이스 업데이트
   */
  update = (
    params: {
      trxId: string
      trxNm: string
      trxExpl?: string
      ruleUseYn: 'Y' | 'N'
      mainTblNm?: string
    } & BaseRequest,
  ): Promise<ApiResponseWithData<{ uptCnt: number }>> => {
    const url = TRX_INFO_API_URL.update
    return this.withData.postJson(url, ...splitParams(params))
  }

  /**
   * 룰 인터페이스 다건 삭제
   */
  delete = (
    params: {
      trxId: string[]
    } & BaseRequest,
  ): Promise<ApiResponseWithData<void>> => {
    const url = TRX_INFO_API_URL.delete
    return this.withData.post(url, ...splitParams(params))
  }
}
