import { ApiHelper, ErrorResponseInterceptor, ResponseInterceptor } from '..'
import { errorToMessage } from './errors/error-messages'
import log from './log'
import { AuthApi } from './user-apis/AuthApi'
import { CLApiPageApi } from './user-apis/CLApiPageApi'
import { CLCodeApi } from './user-apis/CLCodeApi'
import { CLCodeTypeApi } from './user-apis/CLCodeTypeApi'
import { CLEtcApi } from './user-apis/CLEtcApi'
import { CLJobApi } from './user-apis/CLJobApi'
import { CLMsgMngApi } from './user-apis/CLMsgMngApi'
import { CLNavApi } from './user-apis/CLNavApi'
import { CLPrivApi } from './user-apis/CLPrivApi'
import { CLRoleApi } from './user-apis/CLRoleApi'
import { CLRoleUserApi } from './user-apis/CLRoleUserApi'
import { CLSystemLogApi } from './user-apis/CLSystemLogApi'
import { ColmMppnDefnApi } from './user-apis/ColmMppnDefnApi'
import { MetaApi } from './user-apis/MetaApi'
import { MetaGlossaryApi } from './user-apis/MetaGlossaryApi'
import { PdsApi } from './user-apis/PdsApi'
import { ProfileApi } from './user-apis/ProfileApi'
import { RuleApi } from './user-apis/RuleApi'
import { RuleTmplApi } from './user-apis/RuleTmplApi'
import { TeamApi } from './user-apis/TeamApi'
import { TrxInfoApi } from './user-apis/TrxInfoApi'
import { UserApi } from './user-apis/UserApi'
import { UserManageApi } from './user-apis/UserManageApi'
import { VirtualTransApi } from './user-apis/VirtualTransApi'

export class UserMainApi {
  helper: ApiHelper
  // begin clover framework apis
  clCode: CLCodeApi
  clCodeType: CLCodeTypeApi
  clJob: CLJobApi
  clSystemLog: CLSystemLogApi
  clEtc: CLEtcApi
  clNav: CLNavApi
  clPriv: CLPrivApi
  clRole: CLRoleApi
  clRoleUser: CLRoleUserApi
  clPageApi: CLApiPageApi
  clMsgMngApi: CLMsgMngApi
  // end clover framework apis
  auth: AuthApi
  meta: MetaApi
  pds: PdsApi
  user: UserApi
  userManage: UserManageApi
  team: TeamApi
  glossary: MetaGlossaryApi
  profile: ProfileApi
  mppnDefn: ColmMppnDefnApi
  ruleTmpl: RuleTmplApi
  rule: RuleApi
  virTrans: VirtualTransApi
  trxInfo: TrxInfoApi

  constructor(
    public apiBaseURL: string,
    createApiHeader: () => Record<string, string>,
    responseInterceptor: ResponseInterceptor,
    errorResponseInterceptor: ErrorResponseInterceptor,
    debug = false,
  ) {
    log.debug('create Api for ', apiBaseURL)

    // create helper
    const helper = new ApiHelper(
      apiBaseURL,
      createApiHeader,

      responseInterceptor,
      errorResponseInterceptor,
      errorToMessage,
      debug,
    )

    this.helper = helper

    // begin clover framework apis
    this.clCode = new CLCodeApi(helper)
    this.clCodeType = new CLCodeTypeApi(helper)
    this.clJob = new CLJobApi(helper)
    this.clSystemLog = new CLSystemLogApi(helper)
    this.clEtc = new CLEtcApi(helper)
    this.clNav = new CLNavApi(helper)
    this.clPriv = new CLPrivApi(helper)
    this.clRole = new CLRoleApi(helper)
    this.clRoleUser = new CLRoleUserApi(helper)
    this.clPageApi = new CLApiPageApi(helper)
    this.clMsgMngApi = new CLMsgMngApi(helper)
    // end clover framework apis

    this.auth = new AuthApi(helper)
    this.meta = new MetaApi(helper)
    this.pds = new PdsApi(helper)
    this.user = new UserApi(helper)
    this.userManage = new UserManageApi(helper)
    this.team = new TeamApi(helper)
    this.glossary = new MetaGlossaryApi(helper)
    this.profile = new ProfileApi(helper)
    this.mppnDefn = new ColmMppnDefnApi(helper)
    this.ruleTmpl = new RuleTmplApi(helper)
    this.rule = new RuleApi(helper)
    this.trxInfo = new TrxInfoApi(helper)
    this.virTrans = new VirtualTransApi(helper)
  }
}
